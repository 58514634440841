import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { key: 0 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_base_table_column = _resolveComponent("base-table-column")!
  const _component_action_table_column = _resolveComponent("action-table-column")!
  const _component_base_table_row = _resolveComponent("base-table-row")!
  const _component_base_table = _resolveComponent("base-table")!
  const _component_confirm_modal = _resolveComponent("confirm-modal")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_base_table, {
      colspan: "3",
      onLoadMore: _ctx.loadMore,
      loading: _ctx.loading,
      hasMore: _ctx.hasMore,
      noDataAvailable: _ctx.noDataAvailable,
      noResultFound: _ctx.noResultFound
    }, {
      header: _withCtx(() => [
        _createVNode(_component_base_table_row, null, {
          default: _withCtx(() => [
            _createVNode(_component_base_table_column, { type: "label" }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.trans("layout.news.title")), 1)
              ]),
              _: 1
            }),
            _createVNode(_component_base_table_column, { type: "label" }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.trans("layout.news.last_update")), 1)
              ]),
              _: 1
            }),
            _createVNode(_component_action_table_column, { type: "label" })
          ]),
          _: 1
        })
      ]),
      default: _withCtx(() => [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.rows, (row) => {
          return (_openBlock(), _createBlock(_component_base_table_row, {
            key: row.id
          }, {
            default: _withCtx(() => [
              _createVNode(_component_base_table_column, null, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(row.title), 1)
                ]),
                _: 2
              }, 1024),
              _createVNode(_component_base_table_column, null, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.formatDate(row.updated_at)), 1)
                ]),
                _: 2
              }, 1024),
              _createVNode(_component_action_table_column, {
                onDelete: ($event: any) => (_ctx.showConfirmDelete(row)),
                "edit-route": { name: _ctx.RouteNames.NewsEdit, params: { id: row.id } }
              }, null, 8, ["onDelete", "edit-route"])
            ]),
            _: 2
          }, 1024))
        }), 128))
      ]),
      _: 1
    }, 8, ["onLoadMore", "loading", "hasMore", "noDataAvailable", "noResultFound"]),
    _createVNode(_component_confirm_modal, {
      type: "delete",
      title: _ctx.trans('layout.modal_title.delete_news'),
      modelValue: _ctx.confirmDeleteModal,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.confirmDeleteModal) = $event)),
      onYes: _ctx.deleteSelectedRow
    }, {
      default: _withCtx(() => [
        (_ctx.selectedRow)
          ? (_openBlock(), _createElementBlock("p", _hoisted_1, _toDisplayString(_ctx.trans('layout.modal_title.confirm_delete_news', {title: _ctx.selectedRow.title})), 1))
          : _createCommentVNode("", true)
      ]),
      _: 1
    }, 8, ["title", "modelValue", "onYes"])
  ], 64))
}