
import {
  infinityScrollTable,
  helperMixin,
  filterHelper,
  positionFilter
} from "@/mixins";
import { NewsPaginate, NewsPaginateFilter } from "@/models/news";
import { defineComponent } from "vue";
import newsHttp from "@/http/news";
import ActionTableColumn from "@/components/Column/ActionTableColumn.vue";
import BaseTable from "@/components/Base/BaseTable.vue";
import BaseTableRow from "@/components/Base/BaseTableRow.vue";
import BaseTableColumn from "@/components/Base/BaseTableColumn.vue";
import ConfirmModal from "@/components/ConfirmModal.vue";

export default defineComponent({
  components: {
    ActionTableColumn,
    BaseTable,
    BaseTableRow,
    BaseTableColumn,
    ConfirmModal
  },
  mixins: [
    helperMixin,
    filterHelper<NewsPaginateFilter>(),
    positionFilter<NewsPaginateFilter>(),
    infinityScrollTable<NewsPaginate, NewsPaginateFilter>(newsHttp())
  ]
});
